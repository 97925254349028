<template>
  <div class="system">
    <side-nav :active-tab="activeTab" :menu-items="userInfo.menuItems"/>
    <div class="content">
      <router-view/>
    </div>
  </div>
</template>

<script>
import SideNav from '@/views/system/components/SideNav.vue';
import { mapState } from 'vuex';

export default {
  name: 'Index',
  components: { SideNav },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    })
  },
  data() {
    return {
      activeTab: '',
      menuItems: []
    };
  },
  created() {
    const pathArr = this.$route.path.split('/');
    const length = pathArr.length;
    if (length === 2) {
      this.activeTab = `/${pathArr[1]}`;
    } else if (length >= 3) {
      this.activeTab = `/${pathArr[1]}/${pathArr[2]}`;
    }
  }
};
</script>

<style lang="less" scoped>
.system {
  width: 100%;
  display: flex;
  position: absolute;
  top: 48px;
  bottom: 0;

  .content {
    flex: 1;
    padding: 20px;
  }
}
</style>
