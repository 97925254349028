<template>
  <div class="system-side-nav">
    <div class="side-nav-user-info">
      <img alt="头像" src="../../../assets/head2.png">
      <p class="domain">{{ userInfo.username }}</p>
    </div>
    <a-menu v-model="currentKey">
      <a-menu-item key="/system" v-if="menuItems.includes('/system')"><a href="/#/system">管理首页</a></a-menu-item>
      <a-menu-item key="/system/machine" v-if="menuItems.includes('/system/machine')"><a href="/#/system/machine">机器管理</a></a-menu-item>
      <a-menu-item key="/system/datasource" v-if="menuItems.includes('/system/datasource')"><a href="/#/system/datasource">数据源管理</a></a-menu-item>
      <a-menu-item key="/system/sub_account" v-if="menuItems.includes('/system/sub_account')"><a href="/#/system/sub_account">子账号管理</a></a-menu-item>
      <a-menu-item key="/system/role" v-if="menuItems.includes('/system/role')"><a href="/#/system/role">角色管理</a></a-menu-item>
      <a-menu-item key="/system/env" v-if="menuItems.includes('/system/env')"><a href="/#/system/env">环境管理</a></a-menu-item>
      <a-menu-item key="/system/rules" v-if="menuItems.includes('/system/rules')"><a href="/#/system/rules">预检规则管理</a></a-menu-item>
      <a-menu-item key="/system/desensitization" v-if="menuItems.includes('/system/desensitization')"><a href="/#/system/desensitization">数据脱敏</a></a-menu-item>
      <a-menu-item key="/system/data_rules" v-if="menuItems.includes('/system/data_rules')"><a href="/#/system/data_rules">数据处理规则管理</a></a-menu-item>
      <a-menu-item key="/system/data_code" v-if="menuItems.includes('/system/data_code')"><a href="/#/system/data_code">数据处理代码管理</a></a-menu-item>
      <a-menu-item key="/system/async_job_list"  v-if="menuItems.includes('/system/async_job_list')"><a href="/#/system/async_job_list">异步执行管理</a></a-menu-item>
    </a-menu>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SideNav',
  props: {
    activeTab: String,
    menuItems: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      currentKey: []
    };
  },
  created() {
    window.$bus.on('changeSidebar', (name) => {
      console.log('changeSidebar');
      this.currentKey = [name];
    });
  },
  destroyed() {
    window.$bus.off('changeSidebar');
  },
  // created() {
  //   this.$store.dispatch('getUserInfo');
  //   this.currentKey.push(this.activeTab);
  //   console.log('this.currentKey', this.currentKey);
  // },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo
    })
  },
  watch: {
    activeTab: {
      handler(val) {
        this.currentKey = [val];
      },
      immediate: true
    }
  }
};
</script>

<style lang="less" scoped>
.system-side-nav {
  background: #F4F4F4;
  width: 160px;

  .side-nav-user-info {
    text-align: center;
    padding: 30px 0 20px 0;
    background-image: url("../../../assets/bg-img.png");

    img {
      width: 60px;
      height: 60px;
    }

    .domain {
      margin-top: 15px;
      font-size: 14px;
    }

  }

  .ant-menu {
    background: #F4F4F4;

    .ant-menu-item-selected {
      background: #DDDDDD;
      font-weight: 500;
      font-family: PingFangSC-Semibold;
      position: relative;

      &::before {
        content: '';
        height: 100%;
        width: 3px;
        background: #333333;
        position: absolute;
        left: 0;
        top: 0;
      }

      a {
        color: #333333;
      }
    }

    .ant-menu-item > a {
      color: #333333;
    }
  }
}
</style>
